define("discourse/plugins/onlyoffice-discourse-categories/discourse/components/categories", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    categories: {
      docspace: {
        link: "",
        postCount: 0
      },
      documents: {
        link: "",
        postCount: 0
      },
      workspace: {
        link: "",
        postCount: 0
      },
      connectors: {
        link: "",
        postCount: 0
      },
      desktop_editors: {
        link: "",
        postCount: 0
      },
      forms: {
        link: "",
        postCount: 0
      },
      mobile_apps: {
        link: "",
        postCount: 0
      },
      document_builder: {
        link: "",
        postCount: 0
      },
      plugins: {
        link: "",
        postCount: 0
      },
      document_api: {
        link: "",
        postCount: 0
      },
      news: {
        link: "",
        postCount: 0
      },
      suggestions: {
        link: "",
        postCount: 0
      }
    },
    initCategories() {
      if (!this.site.get("categoriesList")) {
        return false;
      }
      Object.values(this.site.get("categoriesList")).forEach(category => {
        let slug = category.slug.replace("-", "_");
        if (this.categories[slug]) {
          this.categories[slug].link += "/c/" + category.slug + "/" + category.id;
          this.categories[slug].postCount = category.post_count;
        }
      });
      return true;
    }
  }, [["method", "initCategories", [(0, _decorators.default)("categories")]]]));
});